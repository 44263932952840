import React, { useState } from 'react';
import { marked } from "marked";
import './tabs.scss';

export const Tabs = (props: TabsProps) => {
  const tabNames = [];
  const tabContent = [];

  props.tabs.forEach(tab => {
    tabNames.push(tab.tabName);
    tabContent.push(marked(tab.tabContent.tabContent));
  });

  const [ active, setActive ] = useState(0);

  return (
    <>
      <ul className="nav nav-tabs" role="tablist">
       { tabNames.map((tabName, index) =>
            <li className={`nav-item ${active === index && 'active'}`} role="presentation" key={`${tabName}_index`}>
              <button
                className={`nav-item nav-link ${active === index && 'active'}`}
                type="button"
                role="tab"
                aria-controls="home"
                aria-selected="true"
                onClick={() => setActive(index)}
              >
                { tabName }
              </button>
            </li>
          )
        }
      </ul>
      <div className="tab-content mt-4">
        { tabContent.map((content, index) =>
            <div
              key={'tab__' + index}
              className={`tab-pane fade show ${active === index && 'active'}`}
              role="tabpanel"
            >
              <div dangerouslySetInnerHTML={{ __html: content }} />
            </div>
          )
        }
      </div>
    </>
  );
};

interface TabsProps {
  tabs: Tab[];
}

interface Tab {
  tabName: string;
  tabContent: { tabContent: string; };
}
