import React, { useContext, useState } from "react";
import { CartContext } from "../providers/Cart";
import { CartActions } from "../providers/Cart/state";

import './AddToCart.scss';

const disallowableKeys = [ 'e', '.', '+', '-' ];

export const AddToCart = (props: AddToCartProps) => {
  const [ numItems, setNumItems ] = useState<string|number>(props.numItems || 1);
  const {
    dispatchCartActions
  } = useContext(CartContext);

  function handleSetNumItems(newNumberOfItems: number) {
    if (newNumberOfItems >= 1) {
      setNumItems(newNumberOfItems);
    } else if (newNumberOfItems <= 0) {
      setNumItems(1);
    } else {
      setNumItems
    }
  }

  function handleButtonClick() {
    dispatchCartActions({
      type: CartActions.ADD_TO_CART,
      payload: {
        numItems,
        productId: props.priceId,
      }
    });
  }

  function handleInputChange(e) {
    let setValue: string | number = parseInt(e.target.value.replace(/\D/,''));
    
    if (isNaN(setValue)) {
      setValue = '';
    }

    setNumItems(setValue);
  }

  function handleKeyPress(e) {
    if (disallowableKeys.indexOf(e.key) > -1) {
        e.preventDefault();
    }
  }

  return (
    <>
      <div className="input-group add-to-cart__number-button me-2">
        <button onClick={() => handleSetNumItems(+numItems - 1)} className="btn btn-primary" type="button" id="button-addon1">-</button>
        <input
          min={1}
          value={numItems}
          onKeyPress={handleKeyPress}
          onChange={handleInputChange}
          type="number"
          className="form-control"
          placeholder=""
          aria-label="Add or Subtract from cart" />
        <button onClick={() => handleSetNumItems(+numItems + 1)} className="btn btn-primary" type="button" id="button-addon1">+</button>
      </div>
      <button onClick={handleButtonClick} className='btn btn-primary'>Add to Cart</button>
    </>
  );
};

interface AddToCartProps {
  priceId: string;
  numItems?: number;
}
