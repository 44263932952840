import { graphql } from "gatsby";
import React from "react";

import "bootstrap/scss/bootstrap.scss";
import NavBar from "../components/nav/NavBar";
import Footer from "../components/footer/Footer";

import "../pages/index.scss";
import "../pages/product-page.scss";

import { marked } from "marked";
import { Tabs } from "../components/tabs/Tabs";
import { AddToCart } from "../components/add-to-cart/AddToCart";
import { ImageSelector } from "../components/image-selector/ImageSelector";

const ContentfulProductPage = (props: any): React.ReactElement<any> => {
  let { unit_amount: unitAmount } = props.data.stripePrice;
  unitAmount = parseFloat(`${unitAmount / 100}`).toFixed(2);
  const { productName, shortDescription, additionalDetails, priceId } =
    props.data.contentfulProductPage;

  const summaryDescription = marked(shortDescription.shortDescription);

  return (
    <>
      <NavBar />
      <div className="container col-md-10 offset-md-1 mt-3">
        <div className="row">
          <div className="col-12 col-md-6">
            <ImageSelector
              images={props.data.contentfulProductPage.images}
              thumbnails={props.data.contentfulProductPage.thumbnails}
              productName={productName}
            />
          </div>

          <div className="col-12 col-md-6">
            <h3 className="product-page__product-name">{productName}</h3>
            <div className="product-page__divider mb-2 mt-2" />
            <h4>
              <sup>$</sup>
              {unitAmount}
            </h4>
            <div
              className="product-page__product-short-description"
              dangerouslySetInnerHTML={{ __html: summaryDescription }}
            />
            <div className="d-flex product-page__add-to-cart">
              <AddToCart priceId={priceId} />
            </div>
          </div>
        </div>
        <div className="row product-page__additional-details mt-3">
          <Tabs tabs={additionalDetails} />
        </div>
      </div>
      <Footer />
    </>
  );
};

export const query = graphql`
  query ($id: String, $priceId: String) {
    contentfulProductPage(id: { eq: $id }, priceId: { eq: $priceId }) {
      id
      productName
      priceId
      shortDescription {
        shortDescription
      }
      images {
        gatsbyImageData(height: 400)
      }
      thumbnails: images {
        gatsbyImageData(height: 100, width: 100, layout: FIXED)
      }
      additionalDetails {
        ... on ContentfulTab {
          tabName
          tabContent {
            tabContent
          }
        }
      }
    }
    stripePrice(id: { eq: $priceId }) {
      unit_amount
      product {
        id
        name
      }
    }
  }
`;

export default ContentfulProductPage;
