import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React, { useEffect, useState } from "react";

import "./image-selector.scss";

export const ImageSelector = (props: ImageSelectorProps) => {
  const [activeImage, setActiveImage] = useState(0);
  const [thumbnails, setThumbnails] = useState([]);
  const [bigImages, setBigImages] = useState([]);

  useEffect(() => {
    setThumbnails(
      props.thumbnails.map((thumbnail) => getImage(thumbnail.gatsbyImageData))
    );

    setBigImages(props.images.map((image) => getImage(image.gatsbyImageData)));
  }, []);

  return (
    <div className="d-flex flex-md-row flex-column-reverse">
      <div className="product-page__thumbnail-container d-flex flex-row flex-md-column me-1 mt-2 mt-md-0">
        {thumbnails.map((thumbnail, index) => {
          return (
            <GatsbyImage
              onClick={() => setActiveImage(index)}
              className={`product-page__thumbnail-image mb-2 me-2 me-md-0 ${
                activeImage === index ? "active" : ""
              }`}
              key={index}
              alt={props.productName + ` image ${index}`}
              image={thumbnail}
            />
          );
        })}
      </div>
      <div className="d-flex col product-image__large-container">
        {bigImages.map((bigImage, index) => {
          const translate = (activeImage - index) * 110;
          return (
            <div
              className={
                activeImage === index
                  ? "product-image__large active col-12"
                  : "product-image__large col-12"
              }
              key={"big-image__" + index}
              style={{
                transform: `translateX(${translate}%)`,
              }}
            >
              <GatsbyImage alt={" "} image={bigImage} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

interface ImageSelectorProps {
  thumbnails: { gatsbyImageData: any }[];
  images: { gatsbyImageData: any }[];
  productName: string;
}
